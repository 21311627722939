import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Figure from "../components/figure"

export default function Apteki() {
  const seo = {
    title: "Apteka Dukla: \"Przy Trakcie\" oraz \"Herbicus\" - sprawdź szczegóły",
    description: "Sprawdź szczegóły: Apteka \"Przy Trakcie\" - Dukla ul. Żwirki i Wigury 1, tel.: 13 43 30 314 | Apteka \"Herbicus\" - Dukla ul. Rynek 17, tel.: 13 43 30 415",
    url: "https://medicusdukla.pl/apteki"
  }

  const webzona = <div id="WEBzona" style={{ display: "none" }}>
    <h1><strong>Apteka Dukla</strong></h1>
    <p><strong>Kiedy na rynku funkcjonuje tak wiele aptek, wybór tej najlepszej może być bardzo trudny. Jednak my wiemy,
      na co zwrócić uwagę dokonując tego wyboru. Sprawdź!</strong></p>
    <p>Czy istnieje dobra i tania apteka? Miejsce, w którym możemy zaoszczędzić przynajmniej kilka złotych na zakupie
      leków? Nasza odpowiedź brzmi – tak! Takim miejscem jest <strong>Apteka Dukla</strong>. Czemu warto wybrać właśnie
      ją? Zachęcamy do przeczytania naszego artykułu.</p>
    <h2><strong>Szeroki wybór produktów w aptece Dukla</strong></h2>
    <p><strong>Apteka Dukla </strong>oferuje swoim klientom niezwykle szeroki asortyment. Zastanów się, ile razy
      zdarzyło się, że musiałeś odwiedzić kilka, czasem nawet kilkanaście aptek, aby kupić to, czego potrzebujesz.
      Właśnie dlatego warto wybrać <strong>aptekę Dukla</strong> – znajdziesz tu wszystkie produkty niezbędne dla Ciebie
      i Twojego zdrowia. W asortymencie <strong>apteki Dukla</strong> znajdują się zarówno suplementy diety i witaminy,
      jak i leki na receptę, co sprawia, że mamy pewność, że każdy będzie zadowolony z zakupów w tym miejscu.</p>
    <p>Warto również wspomnieć, że <a href="https://medicusdukla.pl/uslugi/">przychodnia Medicus-Dukla</a> oferuje
      zdecydowanie szerszy zakres usług, niż same<strong> apteki Dukla</strong>. W skład usług przychodni wchodzi
      również:</p>
    <ul>
      <li><span style={{ textDecoration: "underline" }}>Opieka Pielęgniarska Długoterminowa – pełniona w domu pacjenta przez pielęgniarki opieki długoterminowej (wymagane wcześniejsze skierowanie przez lekarza rodzinnego),</span>
      </li>
      <li><span style={{ textDecoration: "underline" }}>Hospicjum Domowe – opieka nad pacjentem w terminalnej fazie choroby, pełniona w domu pacjenta przez lekarza i pielęgniarkę (niezbędna uprzednia kwalifikacja lekarska),</span>
      </li>
      <li><span style={{ textDecoration: "underline" }}>Medycyna Pracy – wstępne, okresowe i kontrolne badania pracowników zgodnie z art. 229 Kodeksu Pracy,</span>
      </li>
      <li><span style={{ textDecoration: "underline" }}>Badania Kierowców – wszystkie kategorie,</span></li>
      <li><span style={{ textDecoration: "underline" }}>Transport Sanitarny.</span></li>
    </ul>
    <h2><strong><a href="https://medicusdukla.pl/apteki/">Apteka Dukla</a> – sympatyczna i doświadczona obsługa</strong>
    </h2>
    <p>Inną ogromną zaletą <strong>apteki Dukla</strong>, o której nie możemy zapomnieć jest posiadanie niezwykle miłej
      i fachowej obsługi. Udając się do apteki, każdy może liczyć na profesjonalną poradę dotyczącą konkretnych
      preparatów, uwzględniając wskazania do ich stosowania oraz działania niepożądane, jakie mogą wywołać. <a
        href="https://medicusdukla.pl/apteki/"><strong>Apteka Dukla</strong></a> szczyci się profesjonalnym zespołem
      pracowników, którzy dzięki swojemu doświadczeniu odpowiedzą na wszystkie nurtujące pytania naszych klientów.</p>
    <h2>Apteka Dukla i ekstremalnie niskie ceny</h2>
    <p>Ceny leków różnią się znacząco między aptekami – nieczęsto zdarza się, że ten sam lek kosztuje tyle samo w każdej
      z nich. Dlatego też wiele osób szuka rozwiązań, które pozwolą zaoszczędzić, nawet niewielkie kwoty. W
      przypadku<strong> apteki Dukla </strong>nie musisz obawiać się wysokich cen!<strong> Apteka Dukla</strong> jest
      miejscem, w którym tanio kupisz to, czego potrzebujesz – od leków na przeziębienie po kosmetyki.</p>
    <h3>Podsumowanie</h3>
    <p>Jedno jest pewne – dobra apteka to taka, która oferuje swoim klientom szeroką gamę produktów – witaminy,
      suplementy diety, leki dostępne bez recepty, jak i te na receptę.&nbsp; <strong>Apteka Dukla</strong> oprócz tego
      wyróżnia się niebywale sympatyczną i chętną do pomocy obsługą. Wybierz <strong>aptekę Dukla</strong> – ciesz się
      szybkimi i przyjemnymi zakupami!</p>
    <h3>Lokalizacja aptek w dukli</h3>
    <p>Zapraszamy do zakupów leków w aptekach w Dukli:</p>
    <ul>
      <li>
        <h4><strong>Apteka dukla – Przy Trakcie</strong></h4>
      </li>
      <li>
        <h4><strong>Apteka dukla – Herbicus</strong></h4><strong>
      </strong></li>
      <strong>
      </strong></ul>
    <strong>
    </strong></div>

  return (
    <Layout pageClass={"apteki"} bg={"url(/uploads/apteki.jpg)"}>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      {webzona}
      <div className="o-apothecary" data-g="container">
        <h1 className="a-heading-medium">Apteki</h1>
        <div data-g="grid">
          <div data-g="4 6@md" className="card">
            <h2 className="a-heading-medium"><span className={'regular'}>Apteka</span> Przy Trakcie</h2>
            <Figure src="/images/diagnostyka/clock.svg"
                    text={"Poniedziałek - Piątek 8:00 - 19:00 <br /> Sobota 8:00 - 15:00"} />
            <Figure src="/images/diagnostyka/phone.svg" text={"13 43 30 314"} />
            <Figure src="/images/diagnostyka/localization.svg" text={"ul. Żwirki i Wigury 1, Dukla"} />
          </div>
          <div data-g="4 6@md" className="card">
            <h2 className="a-heading-medium"><span className={'regular'}>Apteka</span> Herbicus</h2>
            <Figure src="/images/diagnostyka/clock.svg"
                    text={"Poniedziałek - Piątek 7:30 - 18:00 <br /> Sobota 8:00 - 14:00"}  />
            <Figure src="/images/diagnostyka/phone.svg" text={"13 43 30 415"} />
            <Figure src="/images/diagnostyka/localization.svg" text={"ul.Rynek 17, Dukla"} />
          </div>
        </div>
      </div>
    </Layout>
  )
}
